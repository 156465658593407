import useTheme from '../../../apps/web/src/hooks/useTheme'
import { useEffect, useState } from 'react'
import { ChainId } from '../../swap-sdk/src/chainIds'

export default function RadialGradientByChainUpdater(chainId): string {
  const [activeBackground, setActiveBackground] = useState('')
  const { isDark, resolvedTheme } = useTheme()

  useEffect(() => {
    if (resolvedTheme === "fire") {
      setActiveBackground(`linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url('/images/addedImages/background.png') center 100% no-repeat`)
      return
    }
    switch (chainId) {
      case ChainId.FIRECHAIN:
      case ChainId.RINIA: {
        const firechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #E2E2E2'
        const firechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(226, 226, 226, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? firechainDarkGradient : firechainLightGradient)
        break
      }
      case ChainId.BSC:
      case ChainId.BSC_TESTNET:
      case ChainId.OP_BNB:
      case ChainId.OP_BNB_TESTNET: {
        const bscLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #F0BA03'
        const bscDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(173, 133, 0, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? bscDarkGradient : bscLightGradient)

        break
      }
      case ChainId.FIRECHAIN_ZKEVM:
      case ChainId.FIRECHAIN_ZKEVM_GHOSTRIDER:
      case ChainId.ETHEREUM:
      case ChainId.GOERLI:
      case ChainId.SEPOLIA:
      case ChainId.HOLESKY:
      case ChainId.LINEA_TESTNET: {
        const ethereumLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const ethereumDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? ethereumDarkGradient : ethereumLightGradient)

        break
      }
      case ChainId.POLYGON:
      case ChainId.POLYGON_AMOY:
      case ChainId.POLYGON_ZKEVM:
      case ChainId.POLYGON_ZKEVM_TESTNET: {
        const polygonLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #8247E5'
        const polygonDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(130, 71, 229, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? polygonDarkGradient : polygonLightGradient)
        break
      }
      case ChainId.CRO:
      case ChainId.CRO_TESTNET: {
        const croLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #243367'
        const croDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(36, 51, 103, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? croDarkGradient : croLightGradient)
        break
      }
      case ChainId.AVALANCHE:
      case ChainId.AVALANCHE_FUJI: {
        const avalancheLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #E84142'
        const avalancheDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(232, 65, 66, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? avalancheDarkGradient : avalancheLightGradient)
        break
      }
      case ChainId.FANTOM_OPERA:
      case ChainId.FANTOM_TESTNET: {
        const fantomLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #13B5EC'
        const fantomDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(19, 181, 236, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? fantomDarkGradient : fantomLightGradient)
        break
      }
      case ChainId.CELO:
      case ChainId.CELO_ALFAJORES: {
        const celoLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FCFF52'
        const celoDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(252, 255, 82, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? celoDarkGradient : celoLightGradient)
        break
      }
      case ChainId.OPTIMISM:
      case ChainId.OPTIMISM_GOERLI: {
        const optimismLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FF0420'
        const optimismDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 4, 32, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? optimismDarkGradient : optimismLightGradient)
        break
      }
      case ChainId.ARBITRUM_ONE:
      case ChainId.ARBITRUM_GOERLI: {
        const arbitrumLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #28A0F0'
        const arbitrumDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(40, 160, 240, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? arbitrumDarkGradient : arbitrumLightGradient)
        break
      }
      case ChainId.SHIBARIUM:
      case ChainId.SHIBARIUM_TESTNET: {
        const shibariumLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FFA502'
        const shibariumDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 165, 2, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? shibariumDarkGradient : shibariumLightGradient)
        break
      }
      case ChainId.PULSECHAIN:
      case ChainId.PULSECHAIN_TESTNET: {
        const pulsechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #7C00FF'
        const pulsechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(124, 0, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? pulsechainDarkGradient : pulsechainLightGradient)
        break
      }
      case ChainId.XEN:
      case ChainId.XEN_DEVNET: {
        const xenLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #000000'
        const xenDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 255, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? xenDarkGradient : xenLightGradient)
        break
      }
      case ChainId.HARMONY:
      case ChainId.HARMONY_TESTNET: {
        const harmonyLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #2ECFD6'
        const harmonyDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(46, 207, 214, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? harmonyDarkGradient : harmonyLightGradient)
        break
      }
      case ChainId.COINEX:
      case ChainId.COINEX_TESTNET: {
        const coinexLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #4E4EFF'
        const coinexDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(78, 78, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? coinexDarkGradient : coinexLightGradient)
        break
      }
      case ChainId.DOGECHAIN:
      case ChainId.DOGECHAIN_TESTNET: {
        const dogechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #9255FA'
        const dogechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(146, 85, 250, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? dogechainDarkGradient : dogechainLightGradient)
        break
      }
      case ChainId.ENGRAM_TESTNET: {
        const engramLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #CDF160'
        const engramDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(205, 241, 96) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? engramDarkGradient : engramLightGradient)
        break
      }
      case ChainId.ETHEREUM_CLASSIC:
      case ChainId.ETHEREUM_CLASSIC_TESTNET: {
        const classicLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #2E842E'
        const classicDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(46, 132, 46, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? classicDarkGradient : classicLightGradient)
        break
      }
      case ChainId.FUSION:
      case ChainId.FUSION_TESTNET: {
        const fusionLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #122E5A'
        const fusionDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(18, 46, 90, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? fusionDarkGradient : fusionLightGradient)
        break
      }
      case ChainId.HECO:
      case ChainId.HECO_TESTNET: {
        const hecoLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #3F7FFF'
        const hecoDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(63, 127, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? hecoDarkGradient : hecoLightGradient)
        break
      }
      case ChainId.KCC:
      case ChainId.KCC_TESTNET: {
        const kccLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #3EE6AF'
        const kccDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(62, 230, 175, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? kccDarkGradient : kccLightGradient)
        break
      }
      case ChainId.KLAYTN:
      case ChainId.KLAYTN_BAOBAB: {
        const klaytnLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #A80106'
        const klaytnDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(168, 1, 6, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? klaytnDarkGradient : klaytnLightGradient)
        break
      }
      case ChainId.OKXCHAIN:
      case ChainId.OKXCHAIN_TESTNET: {
        const okxchainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #000000'
        const okxchainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 0, 0, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? okxchainDarkGradient : okxchainLightGradient)
        break
      }
      case ChainId.THETHA:
      case ChainId.THETHA_TESTNET: {
        const thetaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #25B9E7'
        const thetaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(37, 185, 231, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? thetaDarkGradient : thetaLightGradient)
        break
      }
      case ChainId.ULTRON:
      case ChainId.ULTRON_TESTNET: {
        const ultronLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #3E09A2'
        const ultronDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(62, 9, 162, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? ultronDarkGradient : ultronLightGradient)
        break
      }
      case ChainId.MOONBEAM:
      case ChainId.MOONBASE_ALPHA: {
        const moonLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #171E43'
        const moonDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(23, 30, 67, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? moonDarkGradient : moonLightGradient)
        break
      }
      case ChainId.MOONRIVER: {
        const moonriverLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #F3B705'
        const moonriverDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(243, 183, 5, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? moonriverDarkGradient : moonriverLightGradient)
        break
      }
      case ChainId.AURORA:
      case ChainId.AURORA_TESTNET: {
        const auroraLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #6ED34A'
        const auroraDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(110, 211, 74, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? auroraDarkGradient : auroraLightGradient)
        break
      }
      case ChainId.BOBA:
      case ChainId.BOBA_GOERLI:
      case ChainId.BOBA_AVAX:
      case ChainId.BOBA_BNB: {
        const bobaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #1CD8D2'
        const bobaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(28, 216, 210, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? bobaDarkGradient : bobaLightGradient)
        break
      }
      case ChainId.GNOSIS:
      case ChainId.GNOSIS_CHIADO: {
        const gnosisLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00A8C5'
        const gnosisDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0,168,197, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? gnosisDarkGradient : gnosisLightGradient)
        break
      }
      case ChainId.METIS:
      case ChainId.METIS_GOERLI: {
        const metisLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00CFC2'
        const metisDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 207, 194, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? metisDarkGradient : metisLightGradient)
        break
      }
      case ChainId.TELOS:
      case ChainId.TELOS_TESTNET: {
        const telosLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #6D4BB8'
        const telosDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(109, 75, 184, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? telosDarkGradient : telosLightGradient)
        break
      }
      case ChainId.SYSCOIN:
      case ChainId.SYSCOIN_TANENBAUM: {
        const syscoinLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #1F5EFF'
        const syscoinDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(31, 94, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? syscoinDarkGradient : syscoinLightGradient)
        break
      }
      case ChainId.IOTEX:
      case ChainId.IOTEX_TESTNET: {
        const iotexLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00D4D5'
        const iotexDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 212, 213, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? iotexDarkGradient : iotexLightGradient)
        break
      }
      case ChainId.SHIDEN:
      case ChainId.SHIBUNYA: {
        const shidenShibunyaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #AF68FF'
        const shidenShibunyaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(175, 104, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? shidenShibunyaDarkGradient : shidenShibunyaLightGradient)
        break
      }
      case ChainId.ASTAR: {
        const astarLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0077EE'
        const astarDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 119, 238, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? astarDarkGradient : astarLightGradient)
        break
      }
      case ChainId.FUSE:
      case ChainId.FUSE_SPARKNET: {
        const fuseLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #B8FCA4'
        const fuseDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(184, 252, 164, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? fuseDarkGradient : fuseLightGradient)
        break
      }
      case ChainId.VELAS:
      case ChainId.VELAS_TESTNET: {
        const velasLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0037C1'
        const velasDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 55, 193, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? velasDarkGradient : velasLightGradient)
        break
      }
      case ChainId.REI:
      case ChainId.REI_TESTNET: {
        const reiLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #2116E5'
        const reiDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(33, 22, 229, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? reiDarkGradient : reiLightGradient)
        break
      }
      case ChainId.KEKCHAIN:
      case ChainId.KEKCHAIN_TESTNET: {
        const kekchainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #18783C'
        const kekchainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(24, 120, 60, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? kekchainDarkGradient : kekchainLightGradient)
        break
      }
      case ChainId.TOMOCHAIN:
      case ChainId.TOMOCHAIN_TESTNET: {
        const tomochainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00E9B4'
        const tomochainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 233, 180, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? tomochainDarkGradient : tomochainLightGradient)
        break
      }
      case ChainId.THUNDERCORE:
      case ChainId.THUNDERCORE_TESTNET: {
        const thundercoreLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00D6E9'
        const thundercoreDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 214, 233, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? thundercoreDarkGradient : thundercoreLightGradient)
        break
      }
      case ChainId.WANCHAIN:
      case ChainId.WANCHAIN_TESTNET: {
        const wanchainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #136AAD'
        const wanchainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(19, 106, 173, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? wanchainDarkGradient : wanchainLightGradient)
        break
      }
      case ChainId.RSK:
      case ChainId.RSK_TESTNET: {
        const rskLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #4F7B3D'
        const rskDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(79, 123, 61, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? rskDarkGradient : rskLightGradient)
        break
      }
      case ChainId.ELASTOS:
      case ChainId.ELASTOS_TESTNET: {
        const elastosLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #2A3D58'
        const elastosDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(42, 61, 88, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? elastosDarkGradient : elastosLightGradient)
        break
      }
      case ChainId.CONFLUX:
      case ChainId.CONFLUX_TESTNET: {
        const confluxLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #1F909B'
        const confluxDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(31, 144, 155, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? confluxDarkGradient : confluxLightGradient)
        break
      }
      case ChainId.BRISECHAIN:
      case ChainId.BRISECHAIN_TESTNET: {
        const brisechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #630CE5'
        const brisechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(99, 12, 229, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? brisechainDarkGradient : brisechainLightGradient)
        break
      }
      case ChainId.MUUCHAIN:
      case ChainId.MUUCHAIN_TESTNET: {
        const muuchainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #2D3FBD'
        const muuchainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(45, 63, 189, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? muuchainDarkGradient : muuchainLightGradient)
        break
      }
      case ChainId.CANTO:
      case ChainId.CANTO_TESTNET: {
        const cantoLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #06FC99'
        const cantoDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(6, 252, 153, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? cantoDarkGradient : cantoLightGradient)
        break
      }
      case ChainId.OASIS_EMERALD:
      case ChainId.OASIS_TESTNET: {
        const oasisLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0089DB'
        const oasisDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 137, 219, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? oasisDarkGradient : oasisLightGradient)
        break
      }
      case ChainId.VISION:
      case ChainId.VISION_TESTNET: {
        const visionLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'
        const visionDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 255, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? visionDarkGradient : visionLightGradient)
        break
      }
      case ChainId.STEP:
      case ChainId.STEP_TESTNET: {
        const stepLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #F9D549'
        const stepDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(249, 213, 73, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? stepDarkGradient : stepLightGradient)
        break
      }
      case ChainId.METER:
      case ChainId.METER_TESTNET: {
        const meterLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0287D0'
        const meterDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(2, 135, 208, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? meterDarkGradient : meterLightGradient)
        break
      }
      case ChainId.GODWOKEN:
      case ChainId.GODWOKEN_TESTNET: {
        const godwokenLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #00CD9E'
        const godwokenDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 205, 158, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? godwokenDarkGradient : godwokenLightGradient)
        break
      }
      case ChainId.CALLISTO:
      case ChainId.CALLISTO_TESTNET: {
        const callistoLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #21AE54'
        const callistoDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(33, 174, 84, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? callistoDarkGradient : callistoLightGradient)
        break
      }
      case ChainId.EVMOS:
      case ChainId.EVMOS_TESTNET: {
        const evmosLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #ED4E33'
        const evmosDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(237, 78, 51, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? evmosDarkGradient : evmosLightGradient)
        break
      }
      case ChainId.ENERGY_WEB_CHAIN:
      case ChainId.ENERGY_VOLTA: {
        const energyLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #A566FF'
        const energyDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(165, 102, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? energyDarkGradient : energyLightGradient)
        break
      }
      case ChainId.BASE:
      case ChainId.BASE_GOERLI: {
        const baseLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const baseDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? baseDarkGradient : baseLightGradient)
        break
      }

      case ChainId.KAVA:
      case ChainId.KAVA_TESTNET: {
        const kavaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FF413C'
        const kavaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 65, 60, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? kavaDarkGradient : kavaLightGradient)
        break
      }
      case ChainId.CLOVER:
      case ChainId.CLOVER_TESTNET: {
        const cloverLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const cloverDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? cloverDarkGradient : cloverLightGradient)
        break
      }
      case ChainId.DEFICHAIN:
      case ChainId.DEFICHAIN_TESTNET: {
        const defichainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const defichainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? defichainDarkGradient : defichainLightGradient)
        break
      }
      case ChainId.BRONOS:
      case ChainId.BRONOS_TESTNET: {
        const bronosLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const bronosDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? bronosDarkGradient : bronosLightGradient)
        break
      }
      case ChainId.FILECOIN:
      case ChainId.FILECOIN_CALIBRATION: {
        const filecoinLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const filecoinDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? filecoinDarkGradient : filecoinLightGradient)
        break
      }
      case ChainId.FLARE:
      case ChainId.FLARE_TESTNET: {
        const flareLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #E51A57'
        const flareDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(229 ,26, 87, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? flareDarkGradient : flareLightGradient)
        break
      }
      case ChainId.TARAXA:
      case ChainId.TARAXA_TESTNET: {
        const taraxaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0DAE5B'
        const taraxaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(13, 174, 91, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? taraxaDarkGradient : taraxaLightGradient)
        break
      }
      case ChainId.ZKSYNC:
      case ChainId.ZKSYNC_TESTNET: {
        const zksyncLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #0052FF'
        const zksyncDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 82, 255, 1) 0%, rgba(53, 213, 211, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? zksyncDarkGradient : zksyncLightGradient)
        break
      }
      case ChainId.LINEA:
      case ChainId.LINEA_TESTNET: {
        const lineaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const lineaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? lineaDarkGradient : lineaLightGradient)
        break
      }

      case ChainId.BTTCHAIN:
      case ChainId.BTTCHAIN_TESTNET: {
        const bttchainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'
        const bttchainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 255, 255, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? bttchainDarkGradient : bttchainLightGradient)
        break
      }
      case ChainId.CMP:
      case ChainId.CMP_TESTNET: {
        const cmpLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #4700FF'
        const cmpDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(71, 0, 255, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? cmpDarkGradient : cmpLightGradient)
        break
      }
      case ChainId.CORE:
      case ChainId.CORE_TESTNET: {
        const coreLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #FFB600'
        const coreDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(255, 182, 0, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? coreDarkGradient : coreLightGradient)
        break
      }
      case ChainId.CUBECHAIN:
      case ChainId.CUBECHAIN_TESTNET: {
        const cubechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const cubechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? cubechainDarkGradient : cubechainLightGradient)
        break
      }
      case ChainId.DARWINIA_CRAB:
      case ChainId.DARWINIA_PANGOLIN: {
        const darwiniaLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const darwiniaDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? darwiniaDarkGradient : darwiniaLightGradient)
        break
      }
      case ChainId.DIODE_PRETNET:
      case ChainId.DIODE_TESTNET: {
        const diodeLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const diodeDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? diodeDarkGradient : diodeLightGradient)
        break
      }
      case ChainId.GOCHAIN:
      case ChainId.GOCHAIN_TESTNET: {
        const gochainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const gochainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? gochainDarkGradient : gochainLightGradient)
        break
      }
      case ChainId.HAQQ:
      case ChainId.HAQQ_TESTEDGE2: {
        const haqqLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const haqqDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? haqqDarkGradient : haqqLightGradient)
        break
      }
      case ChainId.KARDIACHAIN:
      case ChainId.KARDIACHAIN_TESTNET: {
        const kardiachainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #BE161E'
        const kardiachainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(190, 22, 30, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? kardiachainDarkGradient : kardiachainLightGradient)
        break
      }
      case ChainId.METADIUM:
      case ChainId.METADIUM_TESTNET: {
        const metadiumLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const metadiumDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? metadiumDarkGradient : metadiumLightGradient)
        break
      }
      case ChainId.PALM:
      case ChainId.PALM_TESTNET: {
        const palmLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #4B236B'
        const palmDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(75, 35, 107, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? palmDarkGradient : palmLightGradient)
        break
      }
      case ChainId.POLIS:
      case ChainId.POLIS_TESTNET: {
        const polisLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const polisDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? polisDarkGradient : polisLightGradient)
        break
      }
      case ChainId.SHIBACHAIN: {
        const shibachainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const shibachainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? shibachainDarkGradient : shibachainLightGradient)
        break
      }
      case ChainId.SMART_BCH:
      case ChainId.SMART_BCH_TESTNET: {
        const bchLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const bchDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? bchDarkGradient : bchLightGradient)
        break
      }
      case ChainId.SONGBIRD_CANARY: {
        const songbirdLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const songbirdDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? songbirdDarkGradient : songbirdLightGradient)
        break
      }
      case ChainId.THAICHAIN: {
        const thaichainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const thaichainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? thaichainDarkGradient : thaichainLightGradient)
        break
      }
      case ChainId.UBIQ:
      case ChainId.UBIQ_TESTNET: {
        const ubiqLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const ubiqDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? ubiqDarkGradient : ubiqLightGradient)
        break
      }
      case ChainId.VECHAIN:
      case ChainId.VECHAIN_TESTNET: {
        const vechainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const vechainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? vechainDarkGradient : vechainLightGradient)
        break
      }
      case ChainId.XINFIN:
      case ChainId.XINFIN_APOTHEM: {
        const xinfinLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #244B81'
        const xinfinDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(36, 75, 129, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? xinfinDarkGradient : xinfinLightGradient)
        break
      }
      case ChainId.SCROLL:
      case ChainId.SCROLL_SEPOLIA: {
        const scrollLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #E5D1B8'
        const scrollDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(229, 209, 184, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? scrollDarkGradient : scrollLightGradient)
        break
      }
      case ChainId.XRPL_DEVNET: {
        const xrplDevnetLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #005741'
        const xrplDevnetDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 87, 65, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? xrplDevnetDarkGradient : xrplDevnetLightGradient)
        break
      }
      case ChainId.ZETACHAIN:
      case ChainId.ZETACHAIN_ATHENS: {
        const zetachainLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #005741'
        const zetachainDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(0, 87, 65, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? zetachainDarkGradient : zetachainLightGradient)
        break
      }
      case ChainId.ZYX: {
        const zyxLightGradient =
          'radial-gradient(100% 100% at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), #627FEB'
        const zyxDarkGradient =
          'radial-gradient(50% 50% at 50% 0%, rgba(98, 126, 234, 1) 0%, rgba(31, 33, 40, 0) 100%, rgba(31, 33, 40, 0) 100%), #000000'
        setActiveBackground(isDark ? zyxDarkGradient : zyxLightGradient)
        break
      }
      default: {
        const defaultLightGradient =
          'radial-gradient(100% 100% at 50% 0%, rgba(255, 184, 226, 0.51) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF'
        const defaultDarkGradient = 'linear-gradient(180deg, #202738 0%, #070816 100%)'

        setActiveBackground(isDark ? defaultDarkGradient : defaultLightGradient)
      }
    }
  }, [chainId, isDark, resolvedTheme])

  return activeBackground
}
